document.addEventListener('DOMContentLoaded', () =>
{
    if (window.location.hash === '#login-error')
    {
        document.getElementById('form-login-error').style.display = 'block';
    }

    const passwordShowBoxes = document.getElementsByClassName('password-eye-container');

    Array.from(passwordShowBoxes).forEach((passwordBox) =>
    {
        passwordBox.addEventListener('click', () =>
        {
            const passwordInput = passwordBox.previousElementSibling;
            passwordInput.type = (passwordInput.type === 'password') ? 'text' : 'password';
        });
    });

    const usernameInput = document.querySelector('input[name="login_username"]');
    if (usernameInput !== null)
    {
        if (usernameInput.value === '')
        {
            usernameInput.focus();
        }
        else
        {
            document.querySelector('input[name="login_password"]').focus();
        }
    }
});
